
import { mapGetters } from 'vuex';

export default {
  name: 'PreFooterComponent',
  computed: {
    ...mapGetters({
      siteParts: 'settings/siteParts'
    }),
    outlineButtonLink() {
      return this.siteParts.prefooter?.outlineButton.isExternal
        ? this.siteParts.prefooter?.outlineButton.externalLink
        : this.getPageByRef(this.siteParts.prefooter?.outlineButton?.link?._ref)?.url?.fullPath;
    },
    primaryButtonLink() {
      return this.siteParts.prefooter?.primaryButton.isExternal
        ? this.siteParts.prefooter?.primaryButton.externalLink
        : this.getPageByRef(this.siteParts.prefooter?.primaryButton?.link?._ref)?.url?.fullPath;
    }
  }
};
